$color-primary: black;
$color-secondary: white;
$color-background: $color-secondary;

$font-primary: Arial, sans-serif;
$font-primary-color: $color-primary;

$font-secondary: $font-primary;
$font-secondary-color: $color-secondary;

$breadcrumb-bg: white;