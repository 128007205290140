h3.share {
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

ul.share {
  margin: 0;
  padding: 0;

  li {
    display: inline;

    a {
      text-decoration: none !important;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.icon {
  font-size: 1.5em;
  margin-right: 1em;

  &:hover {
    text-decoration: none;
  }

  &:before {
    font-family: "Font Awesome 5 Brands";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}

.overwrite-color {

  &:before {
    color: black !important;;
  }
}

.twitter::before {
  content: "\f099";
  color: #00a0d1;
}

.facebook::before {
  content: "\f09a";
  color: #4c66a4;
}

.linkedin::before {
  content: "\f0e1";
  color: #0082b9;
}

.whatsapp::before {
  content: "\f232";
  color: #00bd6f;
}

.pinterest::before {
  content: "\f0d2";
  color: #c40000;
}

.mail::before {
  font-family: "Font Awesome 5 Pro";
  color: #c40000;
  content: "\f0e0";
}