.btn {
  font-family: 'Overpass', sans-serif;
  letter-spacing: 1.1px;
  border: none;
  background-color: #aeaeae;
  border-radius: 0;
  color: white;
  cursor: pointer;
  padding: 0px 40px;
  display: inline-block;
  text-transform: lowercase;
  line-height: 1.5em;
  font-weight: 500;
  font-size: 1em;
  outline: none;
  text-decoration: none;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;

  i {
    padding-left: 21px;
    color: white;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.btn-dark {
  font-family: 'Overpass', sans-serif;
  letter-spacing: 1.1px;
  border: none;
  background-color: #535353 !important;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  padding: 10px 21px;
  display: inline-block;
  text-transform: lowercase;
  line-height: 1.5em;
  font-weight: 500;
  font-size: 1em;
  outline: none;
  text-decoration: none;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;

  i {
    padding-left: 21px;
    color: white;
  }

  &:hover {
    color: white;
    background-color: #535353 !important;
    text-decoration: none;
  }

  a {
    color: white;
    background-color: #535353 !important;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: white;
      background-color: #535353 !important;
    }
  }
}


.btn-blue {
  font-family: 'Overpass', sans-serif;
  letter-spacing: 1.1px;
  border: none;
  background-color: #4a5a66 !important;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  padding: 10px 21px;
  display: inline-block;
  text-transform: lowercase;
  line-height: 1.5em;
  font-weight: 500;
  font-size: 1em;
  outline: none;
  text-decoration: none;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;

  i {
    padding-left: 21px;
    color: white;
  }

  &:hover {
    color: white;
    background-color: #4a5a66 !important;
    text-decoration: none;
  }

  a {
    color: white;
    background-color: #4a5a66 !important;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: white;
      background-color: #4a5a66 !important;
    }
  }
}

.photography-button-height {
  margin-top: 47.5%
}

.button-style{
  z-index: 9 !important;
  background-color: #aeaeae00 !important;
}