@media screen and (max-width: 991.9px) {
  .navbar {
    padding: 0.0rem;

    .nav-link {
      padding: 0.75rem;
      color: white !important;
    }

    .navbar-collapse {
      margin-top: -50px;

      .navbar-nav {
        background-color: #8f8f8f;
        width: 50%;
        padding-top: 50px;
        min-height: 2400px;
      }

      .ml-auto {
        margin-left: 0px !important;
      }
    }

    .dropdown-menu {
      width: 100%;
      border: 0px solid;
      padding: 0px;
      border-radius: 0px;
      background-color: transparent;

      .dropdown-item {
        color: white !important;
        text-decoration: none;
        background-color: transparent !important;

        &:hover {
          color: black !important;
          background-color: transparent !important;
        }

        a {
          color: white;
          background-color: transparent !important;

          &:hover {
            color: black !important;
            background-color: transparent !important;
          }
        }

      }
    }

    ul.dropdown-menu li > ul.dropdown-menu {
      left: 100%;
      top: 0;
    }

    ul.dropdown-menu li:hover > ul.dropdown-menu, ul.dropdown-menu li:focus > ul.dropdown-menu {
      display: block
    }
  }

  .navbar-toggler {
    z-index: 999999;
  }
}

@media screen and (min-width: 992px) {
  .navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    transition: padding 0.5s;
    z-index: 9050000;
    text-transform: lowercase;

    .navbar-collapse {
      flex-grow: 0;
    }

    .navbar.compressed {
      padding: 0;

      .navbar-brand {
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          transition: height 0.5s;
          width: 47px;
          height: 47px;
        }
      }
    }

    .nav-link {
      padding: 0.75rem;
      color: white !important;

      &:hover {
        color: black !important;
        background-color: transparent !important;
      }
    }

    .dropdown-menu {
      border: 0px solid;
      padding: 0px;
      border-radius: 0px;
      background-color: transparent;

      .dropdown-item {
        color: white !important;
        padding: 0rem 0.5rem;
        text-decoration: none;
        background-color: transparent !important;

        &:hover {
          color: black !important;
          background-color: transparent !important;
        }

        a {
          color: white;
          background-color: transparent !important;

          &:hover {
            color: black !important;
            background-color: transparent !important;
          }
        }

      }
    }
  }

  ul.dropdown-menu li > ul.dropdown-menu {
    left: 100%;
    top: 0;
    display: flex;
  }

  ul.dropdown-menu li:hover > ul.dropdown-menu, ul.dropdown-menu li:focus > ul.dropdown-menu {
    display: flex;
  }

  .nav li li > a:first-child:nth-last-child(2):after {
    display: none;
  }

  .navbar-brand img {
    width: 47px;
  }

  .compressed .navbar-brand img {
    height: 40px;
  }
}

@include media-breakpoint-up(md) {
  body.customize-support header {
    margin-top: 0;

    .fixed-top {
      top: 32px;
    }
  }
}