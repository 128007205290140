@import "../../../prodacom-bootstrap-theme/assets/scss/main";
@import url('https://fonts.googleapis.com/css2?family=Overpass&display=swap');

@import "button";
@import "contact";
@import "footer";
@import "gallery";
@import "header";

.nav-style {
  z-index: 9000000 !important;
}

#wpadminbar {
  z-index: 9100000 !important;
}

@include media-breakpoint-down(lg) {
  //.navbar {
  //  position: fixed;
  //  z-index: 1030; //this value is from variables.less -> @zindex-navbar-fixed
  //  right: 0;
  //  left: 0;
  //  border-radius: 0;
  //  top: 0;
  //  border-width: 0 0 1px;
  //}

  .blog-header, .primary-content {
    padding: 0 15px 0;
  }
}

.primary-content {
  .breadcrumb {
    padding: 0;
    list-style: none;
    background-color: white;
    border-radius: 0;
  }
}

.custom-background {
  background-color: #aeaeae;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Overpass', sans-serif;
  letter-spacing: 1.1px;
}

p {
  font-family: 'Overpass', sans-serif;
  letter-spacing: 1.1px;
}

.blog-footer {
  background-color: #303030;
  border-top: 1px solid #303030;
  padding: 20px 0;
}

.square {
  position: relative;
  width: 50%;
  padding-bottom: 50%; /* = width for a 1:1 aspect ratio */
  padding-right: 0px;
  padding-left: 0px;
  overflow: hidden;
};


.content {
  position: absolute;
  height: 100%; /* = 100% - 2*5% padding */
  width: 100%; /* = 100% - 2*5% padding */
  padding: 0px;
  overflow: hidden;
}

.image {
  padding: 0px;
  overflow: hidden;
}

img {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  height: 100%;
}

.blog-footer #footer-sidebar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.text-box {
  margin-top: 35px;
  margin-bottom: 35px;
}

.dark-grey-box {
  background-color: #7e7d7d;
}

.dark-grey-background {
  background-color: #535353;
}

.blue-background {
  background-color: #4a5a66 !important;
}

.about {
  position: relative;

  .content-box {
    position: absolute;
  }
}


.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 84px;
  right: 20px;
  display: none;
  background-color: #303030;
  padding: 8px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  border: 0;

  span {
    display: none
  }

  i {
    color: #aeaeae;
    font-size: 21px;
  }
}

.form-control {
  color: #fff;
  background-color: #979797;
  border: 0px;
}