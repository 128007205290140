@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/mixins';

// 2. Vendors
@import 'vendors/wordpress';

// 3. Base stuff
@import 'base/variables';

// 4. Layout-related sections

// 5. Components
@import 'components/social',
'components/tags',
'components/lang-selector',
'components/gallery',
'components/navbar',
'components/edit',
'components/grecaptcha';

// 6. Page-specific styles
//@import 'pages/home';

// 7. Themes
//@import 'themes/default';

/*
 * Globals
 */

body {
  color: $font-primary-color;
  font: $font-primary;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: 0;
    font-family: $font-secondary;
    font-weight: normal;
    color: $font-primary-color;
  }

  a {
    color: inherit;
  }

  .site-title {

    a {
      color: $font-primary-color;
      text-decoration: none;
    }
  }
}

.primary-content {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;

  img {
    @extend .img-fluid;
  }

  nav {
    font-size: 1em;
    color: #CCCCCC;

    a {
      color: #CCCCCC;
    }
  }
}

/*
 * Masthead for nav
 */
.blog-masthead {
  background-color: $color-primary;
  -webkit-box-shadow: inset 0 -2px 5px rgba(0, 0, 0, .1);
  box-shadow: inset 0 -2px 5px rgba(0, 0, 0, .1);
}

/* Nav links */
.blog-nav-item {
  position: relative;
  display: inline-block;
  padding: 10px;
  font-weight: 500;
  color: #cdddeb;
}

.blog-nav-item:hover,
.blog-nav-item:focus {
  color: #fff;
  text-decoration: none;
}

/* Active state gets a caret at the bottom */
.blog-nav .active {
  color: #fff;
}

.blog-nav .active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  vertical-align: middle;
  content: " ";
  border-right: 5px solid transparent;
  border-bottom: 5px solid;
  border-left: 5px solid transparent;
}

/*
 * Blog name and description
 */
.blog-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.site-title {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 60px;
  font-weight: normal;
}

.site-description {
  font-size: 20px;
  color: #999;
}


/*
 * Main column and sidebar layout
 */

.blog-main {
  font-size: 18px;
  line-height: 1.5;
}

/* Sidebar modules for boxing content */
.sidebar-module {
  padding: 15px;
  margin: 0 -15px 15px;
}

.sidebar-module-inset {
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.sidebar-module-inset p:last-child,
.sidebar-module-inset ul:last-child,
.sidebar-module-inset ol:last-child {
  margin-bottom: 0;
}

/* Pagination */
.pager {
  list-style: none;
  margin-bottom: 60px;
  text-align: left;
}

.pager > li > a {
  padding: 10px 20px;
  text-align: center;
  border-radius: 30px;
}

/*
 * Blog posts
 */
.blog-post {
  margin-bottom: 60px;
}

.blog-post-title {
  margin-bottom: 5px;
  font-size: 40px;
}

.blog-post-meta {
  margin-bottom: 20px;
  color: #999;
}

/*
 * Footer
 */
.blog-footer {
  padding: 40px 0;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: 1px solid #e5e5e5;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  #footer-sidebar {
    padding-top: 60px;
    padding-bottom: 60px;

    .widgetcolumn {
      margin-top: 50px;

      h3.widget-title:first-child {
        padding-top: 0
      }

      h3.widget-title {
        padding-top: 30px;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  background-color: black;
  border: 1px solid white;

  span {
    display: none
  }

  i {
    color: white;
    font-size: 30px;
  }
}

.copyright {
  padding-top: 35px;
  padding-bottom: 35px;

  p:last-child {
    margin-bottom: 0;
  }
}

.subtitle {
  margin: 0 0 5px;
  opacity: .75;
}

ul.page-links {
  padding: 40px 0 40px 0;
  list-style-type: none;
}