@media screen and (min-width: 768px){
  .dropdown:hover > .dropdown-menu, .btn-group:hover > .dropdown-menu{
    display: block;
  }
  .dropdown-menu{
    margin-top: 0;
  }
  .dropdown-toggle{
    margin-bottom: 2px;
  }
  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle{
    margin-bottom: 0;
  }
}

.nav-style {
  z-index: 9000000 !important;
}

#wpadminbar {
  z-index: 9100000 !important;
}

.dropdown-item:first-letter {
  text-transform:uppercase;
}

.nav li li > a:first-child:nth-last-child(2):after {
  position: absolute;
  left: auto;
  right: 0.7em;
  top: 0.7em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.nav li li:hover > a:first-child:nth-last-child(2):after {
  top: 0.9em;
  right: 0.5em;
  transition: transform .5s;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}