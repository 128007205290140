$vendors: "-moz-", "-webkit-", "-o-", "-ms-", "";

/*
Gradients
 */
@mixin radial-gradient($from, $to) {
  @each $vendor in $vendors {
    @if $vendor == "-webkit-" {
      background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
    }
    background: #{$vendor}radial-gradient(center, circle cover, $from 0%, $to 100%);
  }
  //fallback
  background-color: $from;
}

@mixin linear-gradient($from-color, $to-color, $opacity, $to-color-percent:100%, $from-color-percent:0%, $direction:top ) {
  @each $vendor in $vendors {
    @if $vendor == "-webkit-" {
      //Chrome 10+
      background-image: #{$vendor}linear-gradient($direction, $from-color $from-color-percent, rgba($to-color, $opacity) $to-color-percent);
    } @else if ($vendor == "-ms-") {
      //Internet Explorer
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($from-color, $opacity))}', endColorstr='#{ie-hex-str(rgba($to-color, $opacity))}', GradientType=0);
    } @else {
      background-image: #{$vendor}linear-gradient($direction, $from-color $from-color-percent, rgba($to-color,$opacity ) $to-color-percent);
    }
  }
  //Fallback
  background-color: $from-color;
}

@mixin gradient($from, $to, $stop: 0.75) {
  @each $vendor in $vendors {
    @if $vendor == "-webkit-" {
      background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to), color-stop($stop, $to));
    }
    background-image: #{$vendor}linear-gradient(top, $from, $to);
  }
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
  //fallback
  background-color: mix($from, $to);
}

//@mixin linear-gradient($top-color, $bottom-color, $opacity, $percent:100%, $top-color-percent:0%) {
//  background: -moz-linear-gradient(top, $top-color $top-color-percent, rgba($bottom-color, $opacity) $percent); /* FF3.6-15 */
//  background: -webkit-linear-gradient(top, $top-color $top-color-percent, rgba($bottom-color, $opacity) $percent); /* Chrome10-25,Safari5.1-6 */
//  background: linear-gradient(to bottom, $top-color $top-color-percent, rgba($bottom-color, $opacity) $percent); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($top-color, $opacity))}', endColorstr='#{ie-hex-str(rgba($bottom-color, $opacity))}', GradientType=0); /* IE6-9 */
//}

/*
Position
 */
@mixin position($sticky) {
  -webkit-position: $sticky;
  -moz-position: $sticky;
  -ms-position: $sticky;
  -o-position: $sticky;
  position: $sticky;
}

/*
Shadows
 */
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    @each $vendor in $vendors {
      #{$vendor}box-shadow: inset $left $top $blur $spread $color;
    }
  } @else {
    @each $vendor in $vendors {
      #{$vendor}box-shadow: $left $top $blur $spread $color;
    }
  }
}

/*
Rounding
 */
@mixin rounded($radius: 0.5em) {
  @each $vendor in $vendors {
    #{$vendor}border-radius: $radius;
  }
}

/*
Grayscale
 */
@mixin grayscale_element($value) {
  -webkit-filter: #{"grayscale(#{$value})"};
  -moz-filter: #{"grayscale(#{$value})"};
  filter: #{"grayscale(#{$value})"};
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}