.edit-link {
  font-size: 1rem;
  color: black;
  top: 0;
  position: absolute;
  right: 0;
  padding: 10px;

  a {
    color: black;
  }
}