.contact {
  padding: 20px !important;
  margin: 0 !important;
}

/*
* Contact form 7
*/
textarea.wpcf7-form-control:focus {
  background: #979797;
  color: white;
}

wpcf7 {
  font-size: 0.75rem !important;
  background: #979797;
  color: white;
  padding:20px;
}
.wpcf7 input[type="text"],
.wpcf7 input[type="email"] {
  background: #979797;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.wpcf7 textarea {
  background: #979797;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.wpcf7 .form-group {
  margin-bottom: 0.5rem;
}


.wpcf7 input[type="submit"],
.wpcf7 input[type="button"] {
  border: none;
  background-color: #979797;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  padding: 10px 40px;
  display: inline-block;
  line-height: 1.5em;
  font-weight: 500;
  font-size: 1em;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    background-color: #979797;
  }
}

span.wpcf7-not-valid-tip {
  color: white;
}

@include media-breakpoint-down(md) {
  .form-control {
    font-size: 0.5rem;
    height: calc(0.5em + 0.5rem + 2px);
  }

  .wpcf7 input[type="submit"], .wpcf7 input[type="button"] {
    border: none;
    background-color: #979797;
    border-radius: 0px;
    color: white;
    cursor: pointer;
    padding: 5px 20px;
    display: inline-block;
    line-height: 0.5em;
    font-weight: 300;
    font-size: 0.5rem;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0px !important;
  }
}