.footer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin: 0;

  .logo-footer {
    width: 47px;
    height: 47px;
    vertical-align: middle;
  }
}

.copyright {
  background-color: white;
  color: black !important;
  padding-top: 14px;
  padding-bottom: 14px;
}

@include media-breakpoint-down(md) {
  .copyright {

    p {
      font-size: 0.75rem;
    }

  }
}