.lang-selector {
    position: absolute;
    top: 104px;

    .current_lang {
        display: block;
    }

    img {
        margin-top: -6px;
    }

    * {
        float:left;
    }

    .lang-dropdown {
        display: none;
    }

    select {
        border: 0;
        font-size: 15px;
        margin-left: 4px;
        background-color: transparent;
        color: $color-primary;
    }
}