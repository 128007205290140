.fa-facebook-f, .fa-facebook, .fa-facebook-square {
    @include transition(.5s);

    &:hover {
        color: #4267B2 !important;
        @include transition(.5s);
    }
}

.fa-twitter, .fa-twitter-square {
    @include transition(.5s);

    &:hover {
        color: #1DA1F2 !important;
        @include transition(.5s);
    }
}

.fa-instagram, .fa-instagram-square {
    @include transition(.5s);

    &:hover {
        color: #8A3AB9 !important;
        @include transition(.5s);
    }
}

.fa-pinterest, .fa-pinterest-square, .fa-pinterest-p {
    @include transition(.5s);

    &:hover {
        color: #E60023 !important;
        @include transition(.5s);
    }
}

.fa-youtube {
    @include transition(.5s);

    &:hover {
        color: #c4302b !important;
        @include transition(.5s);
    }
}

.fa-linkedin-in, .fa-linkedin {
    @include transition(.5s);

    &:hover {
        color: #0072b1 !important;
        @include transition(.5s);
    }
}