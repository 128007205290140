.blocks-gallery-item {
  margin: 0px !important;
}

.blocks-gallery-grid {
  width: 100%;
}

.wp-block-image {
  margin: 0 0 0rem !important;
}